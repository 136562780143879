.ws-content-cta {
	.cta {
		overflow: hidden;
	}

	.cta-background {
		left: 0;
		top: 0;
		backface-visibility: hidden; // added to fix webkit bug jitter
	}

	.cta-content {
		z-index: 1;

		> * {
			// Required setting for IE11
			// Fixes overflow of content
			max-width: 100%;
		}
	}
}
